import type { Files, File } from '~/types';

const KEY = 'global-assets';
let saved: string | null = null;
let isPendingRestore = true;
const WORKER_PATH = '/worker';

export default function () {
    const globalAssets = useState<Files>(KEY, () => []);

    // Restore global assets from local storage
    isPendingRestore &&
        process.client &&
        nextTick(() => {
            saved = localStorage.getItem(KEY);
            if (saved) {
                globalAssets.value = JSON.parse(saved);
            }
            isPendingRestore = false;
        });

    // On change persist into local storage, use Vue 3 watch
    if (process.client) {
        watch(globalAssets, value => {
            localStorage.setItem(KEY, JSON.stringify(value));
        });
    }

    function addAssets(files: Files) {
        globalAssets.value = [...globalAssets.value, ...files]
            // Ensure they're unique by the filename
            .filter(
                (item, index: number, self) =>
                    index === self.findIndex((t: File) => t.name === item.name)
            );
    }

    function removeAssets(files: Files) {
        globalAssets.value = globalAssets.value.filter(
            (item: File) => !files.find(f => f.name === item.name)
        );
    }

    return {
        // State
        globalAssets,
        addAssets,
        removeAssets,

        // Paths
        assetsPreviewPath: WORKER_PATH + '/asset/',
        assetsDownloadPath: WORKER_PATH + '/download/',
        assetsZipPath: WORKER_PATH + '/zip-me/',
    };
}
